<template>
  <div v-loading="loading" class="reconciliation-task" @click="onClickOutside">
    <SingularTaskLayout
      :title="$t('tasks.reconciliationTask.title')"
      :task="currentTask"
      :is-loading-task="loading"
      :validate-task-before-complete-hook="validateTaskHook"
      :requests-target-businesses-ids="[supplier.id, currentTask.businessId]"
      :is-actions-disabled="actionsDisabled"
      @complete-task="completedTask"
      @skip-task="onTaskSkip"
    >
      <template slot="menu-dropdown-items">
        <div class="d-flex align-items-baseline">
          <Button
            v-if="!actionsLoading && channelUnreadMessagesCount[reconciliation.id] >= 0"
            class="position-relative p-1"
            type="icon"
            @click.stop="
              handleChatOpen({
                supplier,
                reconciliationId: reconciliation.id,
                reconciliationPeriod: reconciliation.periodStart,
                reconciliationClosed: reconciliation.closed,
                isDaily: reconciliation.periodStart === reconciliation.periodEnd,
              })
            "
          >
            <ChatIcon />
            <span
              v-if="channelUnreadMessagesCount[reconciliation.id] > 0"
              class="badge rounded-pill top-0 bg-danger position-absolute border border-2 border-white"
              :class="{ ['start-0']: $direction === 'rtl', ['end-0']: $direction === 'ltr' }"
            >
              {{
                channelUnreadMessagesCount[reconciliation.id] > 99
                  ? '+99'
                  : channelUnreadMessagesCount[reconciliation.id]
              }}
            </span>
          </Button>
          <el-dropdown
            v-if="!actionsLoading"
            class="d-flex justify-content-center"
            trigger="click"
            placement="bottom"
            @command="(command) => handleAction(command)"
          >
            <Button type="icon" class="action-btn p-0 mx-1">
              <KebabIcon />
            </Button>
            <el-dropdown-menu>
              <el-dropdown-item
                v-if="isAdmin"
                :disabled="!mainStatus || mainStatus.status === RECONCILIATION_STATUSES.APPROVED"
                :command="ACTIONS.OPEN_ISSUES_MODAL"
              >
                <div class="d-flex align-items-center gap-2">
                  <AccountingIcon />
                  {{ $t('billing.billingManagement.billingTable.actions.openIssuesModal') }}
                </div>
              </el-dropdown-item>
              <el-dropdown-item v-if="isAdmin" :command="ACTIONS.OPEN_CREDIT_MATCH_MODAL">
                <div class="d-flex align-items-center gap-2">
                  <DocumentMatchingIcon />
                  {{ $t('billing.billingManagement.billingTable.actions.openCreditMatchModalData') }}
                </div>
              </el-dropdown-item>
              <el-dropdown-item v-if="isAdmin" divided :command="ACTIONS.OPEN_RECONCILIATION_SETTINGS">
                <div class="d-flex align-items-center gap-2">
                  <SettingsIcon />
                  {{ $t('billing.billingManagement.billingTable.actions.singleReconciliationSettings') }}
                  <Tag v-if="!isSupplierTemplateDefined">
                    {{ $t('billing.billingManagement.billingTable.actions.default') }}
                  </Tag>
                </div>
              </el-dropdown-item>
              <el-dropdown-item v-if="isAdmin" :command="ACTIONS.BALANCE_UPDATE" :disabled="!!balancePayment">
                <div class="d-flex align-items-center gap-2">
                  <SettingsIcon />
                  <p>{{ $t('payment.paymentTable.singleRowActions.balanceUpdate') }}</p>
                </div>
              </el-dropdown-item>
              <el-dropdown-item
                v-if="isAdmin && reconciliation.balanceAlignment"
                divided
                :disabled="!reconciliation.balanceAlignment.required || reconciliation.balanceAlignment.validated"
                :command="ACTIONS.VALIDATE_BALANCE_ALIGNMENT"
              >
                <div class="d-flex align-items-center gap-2">
                  <CheckCircleIcon />
                  {{ $t('billing.billingManagement.billingTable.actions.validateBalanceAlignment') }}
                </div>
              </el-dropdown-item>
              <el-dropdown-item
                v-if="isAdmin"
                :disabled="reconciliation.totalPaidAmount !== reconciliation.totalBilledAmount || reconciliation.closed"
                :command="ACTIONS.CLOSE_RECONCILIATION"
              >
                <div
                  class="d-flex align-items-center gap-2"
                  :class="`${
                    reconciliation.totalPaidAmount !== reconciliation.totalBilledAmount || reconciliation.closed
                      ? 'text-disabled'
                      : 'text-typography-primary'
                  }`"
                >
                  <ChequeredFlagIcon />
                  <p>{{ $t('payment.paymentTable.singleRowActions.closeReconciliation') }}</p>
                </div>
              </el-dropdown-item>
              <el-dropdown-item divided :command="ACTIONS.OPEN_CHAT">
                <div class="d-flex align-items-center gap-2">
                  <ChatIcon />
                  {{ $t('billing.billingManagement.billingTable.actions.openChat') }}
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </template>
      <template #content>
        <div v-loading="refetchLoading">
          <div class="details-container p-6 my-7">
            <div class="d-flex flex-row align-items-center">
              <el-popover :value="contactPopover" trigger="manual" placement="bottom" popper-class="p-0">
                <ContactCard
                  v-if="contactUser"
                  :user="contactUser"
                  :customer="business"
                  :supplier="supplier"
                  :token="token"
                />
                <template #reference>
                  <Button
                    type="link"
                    class="text-typography-primary p-0"
                    @click.stop="openContactPopover({ type: 'tenant', payload: { worksAt: businessId } })"
                  >
                    <h3>{{ business?.name }}</h3>
                  </Button>
                  <ExchangeIcon class="mx-1" />
                  <Button
                    type="link"
                    class="text-typography-primary p-0"
                    @click.stop="
                      openContactPopover({
                        type: 'supplier',
                        payload: { worksAt: supplier.id, businessId: businessId },
                      })
                    "
                  >
                    <h3>{{ supplier.name }}</h3>
                  </Button>
                </template>
              </el-popover>
            </div>
            <div class="d-flex gap-3 mt-4">
              <p class="detail">{{ $t('tasks.details.comments') }}</p>
              <p>{{ taskComment }}</p>
            </div>
            <div class="d-flex align-items-center gap-3 mt-4">
              <p class="detail">{{ $t('tasks.details.reconciliationSettings') }}</p>
              <div class="d-flex align-items-center gap-2">
                <div v-if="reconciliationTemplate.note">
                  <p v-if="reconciliationTemplate.note.length < 80">{{ reconciliationTemplate.note }}</p>
                  <p v-else style="width: 552px">
                    <TruncatedText>{{ reconciliationTemplate.note }}</TruncatedText>
                  </p>
                </div>
                <div>
                  <Button
                    type="link"
                    class="text-typography-primary p-0 link"
                    @click="handleAction(ACTIONS.OPEN_RECONCILIATION_SETTINGS)"
                    >{{ $t('tasks.details.showSettings') }}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between gap-2 mb-6">
            <div class="d-flex align-items-start">
              <div
                v-if="periodComplete !== RECONCILIATION_STATUSES.APPROVED"
                class="before-payment-due-circle d-flex align-items-center justify-content-center"
                style="margin-top: 3px"
              >
                <HourglassIcon />
              </div>
              <StatusOverrideModal
                v-else
                :show="overrideModalShow"
                :reconciliation-status="reconciliation.status"
                :reconciliation-id="reconciliation.id"
                :status-override="statusOverride"
                :business-id="businessId"
                :supplier="supplier"
                @close="overrideModalShow = false"
              >
                <Button
                  slot="reference"
                  type="link"
                  class="text-typography-primary ms-2 p-0 status-override"
                  @click.stop="overrideModalShow = true"
                >
                  <div style="margin-top: 3px">
                    <p
                      v-if="statusOverride"
                      class="main-status-circle"
                      :class="{
                        [`border border-4 border-${RECONCILIATION_STATUS_COLOR[statusOverride.status]}`]: true,
                      }"
                    ></p>
                    <div v-else-if="mainStatus === RECONCILIATION_STATUSES.APPROVED" class="approved">
                      <ApprovedIcon class="mx-1" />
                    </div>
                    <p
                      v-else
                      class="main-status-circle"
                      :class="{
                        [`bg-${RECONCILIATION_STATUS_COLOR[mainStatus]}`]: true,
                        ['border border-4 border-grey']: reconciliationLoading,
                      }"
                    >
                      {{ reconciliationLoading ? '' : problemCount }}
                    </p>
                  </div>
                </Button>
              </StatusOverrideModal>
              <el-dropdown trigger="click" @command="handleReconciliationSelect">
                <span class="el-dropdown-link">
                  <h2 class="reconciliation-title">
                    {{
                      $t('reconciliationModal.reconciliation', {
                        date: formatDateShort(dateRange.toDate, dateRange.isDaily),
                      })
                    }}
                  </h2>
                  <ChevronIcon />
                </span>
                <el-dropdown-menu slot="dropdown" class="reconciliation-select">
                  <el-dropdown-item
                    v-for="reconciliationOption in reconciliationOptions"
                    :key="reconciliationOption.id"
                    :command="reconciliationOption.id"
                    :disabled="reconciliationOption.disabled"
                  >
                    {{ reconciliationOption.label }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div class="d-flex align-items-baseline">
              <Button
                v-if="!actionsLoading && channelUnreadMessagesCount[reconciliation.id] >= 0"
                class="position-relative p-1"
                type="icon"
                @click.stop="
                  handleChatOpen({
                    supplier,
                    reconciliationId: reconciliation.id,
                    reconciliationPeriod: reconciliation.periodStart,
                    reconciliationClosed: reconciliation.closed,
                    isDaily: reconciliation.periodStart === reconciliation.periodEnd,
                  })
                "
              >
                <ChatIcon />
                <span
                  v-if="channelUnreadMessagesCount[reconciliation.id] > 0"
                  class="badge rounded-pill top-0 bg-danger position-absolute border border-2 border-white"
                  :class="{ ['start-0']: $direction === 'rtl', ['end-0']: $direction === 'ltr' }"
                >
                  {{
                    channelUnreadMessagesCount[reconciliation.id] > 99
                      ? '+99'
                      : channelUnreadMessagesCount[reconciliation.id]
                  }}
                </span>
              </Button>
              <el-dropdown
                v-if="!actionsLoading"
                class="d-flex justify-content-center"
                trigger="click"
                placement="bottom"
                @command="(command) => handleAction(command)"
              >
                <Button type="icon" class="action-btn p-0 mx-1">
                  <KebabIcon />
                </Button>
                <el-dropdown-menu>
                  <el-dropdown-item
                    v-if="isAdmin"
                    :disabled="!mainStatus || mainStatus.status === RECONCILIATION_STATUSES.APPROVED"
                    :command="ACTIONS.OPEN_ISSUES_MODAL"
                  >
                    <div class="d-flex align-items-center gap-2">
                      <AccountingIcon />
                      {{ $t('billing.billingManagement.billingTable.actions.openIssuesModal') }}
                    </div>
                  </el-dropdown-item>
                  <el-dropdown-item v-if="isAdmin" :command="ACTIONS.OPEN_CREDIT_MATCH_MODAL">
                    <div class="d-flex align-items-center gap-2">
                      <DocumentMatchingIcon />
                      {{ $t('billing.billingManagement.billingTable.actions.openCreditMatchModalData') }}
                    </div>
                  </el-dropdown-item>
                  <el-dropdown-item v-if="isAdmin" divided :command="ACTIONS.OPEN_RECONCILIATION_SETTINGS">
                    <div class="d-flex align-items-center gap-2">
                      <SettingsIcon />
                      {{ $t('billing.billingManagement.billingTable.actions.singleReconciliationSettings') }}
                      <Tag v-if="!isSupplierTemplateDefined">
                        {{ $t('billing.billingManagement.billingTable.actions.default') }}
                      </Tag>
                    </div>
                  </el-dropdown-item>
                  <el-dropdown-item v-if="isAdmin" :command="ACTIONS.BALANCE_UPDATE" :disabled="!!balancePayment">
                    <div class="d-flex align-items-center gap-2">
                      <SettingsIcon />
                      <p>{{ $t('payment.paymentTable.singleRowActions.balanceUpdate') }}</p>
                    </div>
                  </el-dropdown-item>
                  <el-dropdown-item
                    v-if="isAdmin && reconciliation.balanceAlignment"
                    divided
                    :disabled="!reconciliation.balanceAlignment.required || reconciliation.balanceAlignment.validated"
                    :command="ACTIONS.VALIDATE_BALANCE_ALIGNMENT"
                  >
                    <div class="d-flex align-items-center gap-2">
                      <CheckCircleIcon />
                      {{ $t('billing.billingManagement.billingTable.actions.validateBalanceAlignment') }}
                    </div>
                  </el-dropdown-item>
                  <el-dropdown-item
                    v-if="isAdmin"
                    :disabled="
                      reconciliation.totalPaidAmount !== reconciliation.totalBilledAmount || reconciliation.closed
                    "
                    :command="ACTIONS.CLOSE_RECONCILIATION"
                  >
                    <div
                      class="d-flex align-items-center gap-2"
                      :class="`${
                        reconciliation.totalPaidAmount !== reconciliation.totalBilledAmount || reconciliation.closed
                          ? 'text-disabled'
                          : 'text-typography-primary'
                      }`"
                    >
                      <ChequeredFlagIcon />
                      <p>{{ $t('payment.paymentTable.singleRowActions.closeReconciliation') }}</p>
                    </div>
                  </el-dropdown-item>
                  <el-dropdown-item divided :command="ACTIONS.OPEN_CHAT">
                    <div class="d-flex align-items-center gap-2">
                      <ChatIcon />
                      {{ $t('billing.billingManagement.billingTable.actions.openChat') }}
                    </div>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
          <div v-if="!loading" class="d-flex justify-content-between gap-4">
            <ReconciliationInfoCard
              class="col-6 mb-7"
              :reconciliation="reconciliation"
              :reconciliation-refetch="reconciliationRefetch"
              :supplier="supplier"
              :customer="business"
            />
            <StatusReflectionCard
              class="mb-7 flex-fill"
              :status-reflection="statusReflection"
              :business-id="businessId"
              :supplier="supplier"
              :reconciliation="reconciliation"
              :reconciliation-id="reconciliation.id"
              :is-admin="isAdmin"
              :tasks="reconciliationTasks"
              @update-task="taskUpdate"
            />
          </div>
          <div class="mb-7 d-flex pe-3">
            <div class="d-flex align-items-stretch">
              <div class="stepper"></div>
            </div>
            <div class="w-100">
              <div class="d-flex align-items-baseline gap-4">
                <div class="position-relative pb-2 ms-1">
                  <span
                    class="top-50 start-50 translate-middle position-absolute rounded-circle center border border-4"
                    :class="{
                      [`bg-${RECONCILIATION_STATUS_COLOR[snapshotStatus]}`]: true,
                      'border-grey': reconciliationLoading,
                      'border-white': !reconciliationLoading,
                    }"
                    style="padding: 5px"
                  />
                </div>
                <div class="d-flex">
                  <h3 class="mb-5">{{ $t('reconciliationModal.snapshotAlignment') }}</h3>
                  <el-tooltip placement="top" effect="dark">
                    <div slot="content">
                      <p>{{ $t('reconciliationModal.tooltips.snapshotAlignmentFirstRow') }}</p>
                      <p>{{ $t('reconciliationModal.tooltips.snapshotAlignmentSecondRow') }}</p>
                    </div>
                    <QuestionMarkIcon class="m-1" width="12" height="12" />
                  </el-tooltip>
                </div>
              </div>
              <div id="balanceAlignmentStatus" class="d-flex align-items-top mb-2 gap-4">
                <div class="position-relative pb-2 ms-1" style="height: 52px">
                  <span
                    class="position-absolute top-50 start-50 translate-middle p-1 rounded-circle center border border-4"
                    :class="{
                      [`bg-${RECONCILIATION_STATUS_COLOR[balanceAlignmentStatus]}`]: true,
                      'border-grey': reconciliationLoading,
                      'border-white': !reconciliationLoading,
                    }"
                  />
                </div>
                <div :id="`card-${balanceAlignmentCardId}`" class="card w-100">
                  <div
                    class="header"
                    data-bs-toggle="collapse"
                    :data-bs-target="`#${balanceAlignmentCardId}`"
                    aria-expanded="false"
                    :aria-controls="balanceAlignmentCardId"
                  >
                    <div class="d-flex pe-4 ps-3 align-items-stretch h-100">
                      <h4 class="align-self-center">
                        {{ $t('reconciliationModal.balanceAlignment') }}
                      </h4>
                    </div>
                  </div>
                  <div :id="balanceAlignmentCardId" class="collapse">
                    <ReconciliationDocuments
                      v-if="reconciliation.supplierId"
                      :balance-payment="balancePayment"
                      :balance-alignment="reconciliation.balanceAlignment"
                      :reconciliation-document-status="balanceAlignmentStatus"
                      :date-range="dateRange"
                      :documents="reconciliationDocuments"
                      :loading="reconciliationDocumentsLoading"
                      @open-document="handleOpenDocument"
                    />
                  </div>
                </div>
              </div>
              <div id="paymentDifferencesStatus" class="d-flex align-items-top mb-2 gap-4">
                <div class="position-relative pb-2 ms-1" style="height: 52px">
                  <span
                    class="position-absolute top-50 start-50 translate-middle p-1 rounded-circle center border border-4"
                    :class="{
                      [`bg-${RECONCILIATION_STATUS_COLOR[paymentDifferenceStatus]}`]: true,
                      'border-grey': reconciliationLoading,
                      'border-white': !reconciliationLoading,
                    }"
                  />
                </div>
                <div :id="`card-${paymentDifferencesCardId}`" class="card w-100">
                  <div
                    class="header"
                    data-bs-toggle="collapse"
                    :data-bs-target="`#${paymentDifferencesCardId}`"
                    aria-expanded="false"
                    :aria-controls="paymentDifferencesCardId"
                  >
                    <div class="d-flex pe-4 ps-3 align-items-stretch h-100">
                      <h4 class="align-self-center">
                        {{ $t('reconciliationModal.paymentDifferences') }}
                      </h4>
                    </div>
                  </div>
                  <div :id="paymentDifferencesCardId" class="collapse">
                    <PaymentDifferences
                      v-if="business"
                      :payment-differences="customerPaymentDifferences"
                      :business="business"
                      @match-payment-diffs="openPaymentDifferencesMatchingModal = true"
                      @open-document="handleOpenDocument"
                    />
                  </div>
                </div>
              </div>
              <div id="creditNotesStatus" class="d-flex align-items-top mb-2 gap-4">
                <div class="position-relative pb-2 ms-1" style="height: 52px">
                  <span
                    class="position-absolute top-50 start-50 translate-middle p-1 rounded-circle center border border-4"
                    :class="{
                      [`bg-${RECONCILIATION_STATUS_COLOR[creditNotesStatus]}`]: true,
                      'border-grey': reconciliationLoading,
                      'border-white': !reconciliationLoading,
                    }"
                  />
                </div>
                <div :id="`card-${creditNotesToMatchCardId}`" class="card w-100">
                  <div
                    class="header"
                    data-bs-toggle="collapse"
                    :data-bs-target="`#${creditNotesToMatchCardId}`"
                    aria-expanded="false"
                    :aria-controls="creditNotesToMatchCardId"
                  >
                    <div class="d-flex pe-4 ps-3 align-items-stretch h-100">
                      <h4 class="align-self-center">
                        {{ $t('reconciliationModal.creditNotesToMatch') }}
                      </h4>
                      <span class="credit-circle">
                        {{ creditNotesToMatch.length }}
                      </span>
                    </div>
                  </div>
                  <div :id="creditNotesToMatchCardId" class="collapse">
                    <CreditNotes
                      :credit-notes="creditNotesToMatch"
                      @match-credits="handleOpenMatchCredits"
                      @open-event-map="handleOpenEventMap"
                      @open-document="handleOpenDocument"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex pe-3">
            <div class="d-flex align-items-stretch">
              <div class="stepper"></div>
            </div>
            <div class="w-100">
              <div class="d-flex align-items-baseline gap-4">
                <div class="position-relative pb-2 ms-1">
                  <span
                    class="top-50 start-50 translate-middle position-absolute rounded-circle center border border-4"
                    :class="{
                      [`bg-${RECONCILIATION_STATUS_COLOR[billingVerificationStatus]}`]: true,
                      'border-grey': reconciliationLoading,
                      'border-white': !reconciliationLoading,
                    }"
                    style="padding: 5px"
                  />
                </div>
                <div class="d-flex">
                  <h3 class="mb-5">{{ $t('reconciliationModal.billingVerification') }}</h3>
                  <el-tooltip placement="top" effect="dark">
                    <div slot="content">
                      <p>{{ $t('reconciliationModal.tooltips.billingVerificationFirstRow') }}</p>
                      <p>{{ $t('reconciliationModal.tooltips.billingVerificationSecondRow') }}</p>
                    </div>
                    <QuestionMarkIcon class="m-1" width="12" height="12" />
                  </el-tooltip>
                </div>
              </div>
              <div class="d-flex px-3 py-4 fw-bold" :class="$direction === 'rtl' ? 'me-5' : 'ms-5'">
                <div style="width: 250px">{{ $t('reconciliationModal.billingTable.reference') }}</div>
                <div style="width: 140px">{{ $t('commons.date') }}</div>
                <div style="width: 140px">{{ $t('commons.total') }}</div>
                <div class="flex-fill">{{ $t('reconciliationModal.billingStatus.reconciliationStatus') }}</div>
                <div style="width: 165px">{{ $t('reconciliationModal.billingStatus.paymentStatus') }}</div>
                <div style="width: 32px"></div>
              </div>
              <div
                v-for="(billing, index) in reconciliation.billings"
                :id="`status-${billing.id}`"
                :key="index"
                class="d-flex mb-2"
              >
                <ReconciliationBillingRow
                  :index="index"
                  :billing="billing"
                  :loading="reconciliationLoading"
                  :delivery-tasks-ids="deliveryTasksIds"
                  :billings="reconciliation.billings"
                  :reconciliation-month="reconciliationMonth"
                  :supplier="supplier"
                  :business="business"
                  @handleUnbilledOrdersTableRefetch="handleUnbilledOrdersTableRefetch"
                  @handleBillingTableRefetch="handleBillingTableRefetch"
                  @handlePaymentLinkClicked="handlePaymentLinkClicked"
                  @handleOpenDocument="handleOpenDocument"
                  @patchMissingEvent="patchMissingEvent"
                  @handleOpenEventMap="handleOpenEventMap"
                />
              </div>
            </div>
          </div>
        </div>
        <hr class="my-7" />
        <div class="status-reflection pe-3 mt-7">
          <h4>
            {{ $t('tasks.reconciliationTask.statusReflection') }}
          </h4>
          <el-form ref="statusReflection" :model="statusReflectionForm" :show-message="false">
            <el-form-item prop="update" class="my-4" required>
              <el-radio-group
                v-model="statusReflectionForm.update"
                class="d-flex align-items-center gap-5"
                :class="{ 'radio-red-border': validationError }"
              >
                <el-radio :label="true" class="mx-0 d-flex">
                  <p>{{ $t('tasks.reconciliationTask.update') }}</p>
                </el-radio>
                <el-radio :label="false" class="d-flex">
                  <p>{{ $t('tasks.reconciliationTask.noUpdate') }}</p>
                </el-radio>
              </el-radio-group>
              <div v-if="validationError" class="mt-1">
                <div class="d-flex align-items-center">
                  <ErrorFullIcon />
                  <p class="red me-1">{{ $t('tasks.reconciliationTask.noChoiceMade') }}</p>
                </div>
              </div>
            </el-form-item>
            <el-form-item>
              <el-input
                v-model="statusReflectionForm.reason"
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 10 }"
                :disabled="statusReflectionDisabled"
              />
            </el-form-item>
          </el-form>
          <p class="text-typography-secondary mt-2">
            {{ $t('tasks.reconciliationTask.statusReflectionDescription') }}
          </p>
        </div>
      </template>
      <template #play-mode-indicator>
        <slot name="play-mode-indicator"></slot>
      </template>
    </SingularTaskLayout>
    <DocumentModal
      v-if="displayedDocumentId"
      visible
      :document-id="displayedDocumentId"
      @close="displayedDocumentId = null"
    />
    <PaymentDifferencesMatchingModal
      v-if="openPaymentDifferencesMatchingModal"
      :supplier="supplier"
      :payment-differences="supplierPayments"
      @close="openPaymentDifferencesMatchingModal = false"
      @paymentDifferenceMatchSuccess="handlePaymentsMatchSuccess"
    />
    <CreditMatchModal
      v-if="creditMatchModalData"
      :selected-billing-id="creditMatchModalData.billingId"
      :default-month="creditMatchModalData.month"
      :supplier="supplier"
      :business-id="businessId"
      @close="creditMatchModalData = null"
    />
    <EventMapModal v-if="activity" :activity="activity" @close="activity = null" />
    <IssuesModal
      v-if="issueModalData"
      :month="issueModalData && issueModalData.month"
      :supplier-id="issueModalData && issueModalData.supplierId"
      :business-id="businessId"
      :billing-id="issueModalData && issueModalData.billingId"
      @close="issueModalData = null"
    />
    <ReconciliationSettingsModal
      v-if="reconciliationTemplateParams"
      :supplier="supplier"
      :business="business"
      :reconciliation-template="reconciliationTemplateParams.template"
      @close="reconciliationTemplateParams = null"
      @save="(params) => handleReconciliationTemplateSave(params)"
    />
    <BalancePaymentModal
      v-if="balancePaymentModalSupplier"
      :supplier="balancePaymentModalSupplier"
      :business-id="businessId"
      @close="balancePaymentModalSupplier = null"
      @balance-payment-create="refetchData"
    />
    <ReconciliationModal
      v-if="selectedReconciliationId"
      :reconciliation-id="selectedReconciliationId"
      @close="handleReconciliationModalClose"
      @open-chat="handleChatOpen"
      @update="refetchData"
    />
    <BalanceAlignmentModal
      v-if="balanceAlignmentReconciliation"
      :reconciliation="balanceAlignmentReconciliation"
      @close="balanceAlignmentReconciliation = null"
      @validate="validateBalanceAlignment"
    />
  </div>
</template>

<script>
import { computed, ref, reactive, onBeforeUnmount, getCurrentInstance, watch } from 'vue';
import { DateTime } from 'luxon';
import { reject, isNil, uniq, flatten, uniqBy, prop, pluck, isEmpty, equals, sum, all, pipe, head } from 'ramda';
import { Collapse } from 'bootstrap';

import { Button, TruncatedText, Tag } from '@/modules/core';
import {
  ExchangeIcon,
  HourglassIcon,
  ApprovedIcon,
  ChevronIcon,
  QuestionMarkIcon,
  KebabIcon,
  ChatIcon,
  AccountingIcon,
  DocumentMatchingIcon,
  SettingsIcon,
  CheckCircleIcon,
  ChequeredFlagIcon,
  ErrorFullIcon,
} from '@/assets/icons';
import {
  useReconciliation,
  useEventDifferences,
  useReconciliationsSummary,
  useReconciliationDocuments,
  useBillings,
  useMissingEvents,
  usePatchMissingEvent,
  useReconciliationTemplate,
  useCreateReconciliationTemplate,
  useUpdateReconciliationTemplate,
  useUpdateReconciliation,
  useCloseReconciliation,
  useChannel,
  CreditNotes,
  PaymentDifferences,
  ReconciliationDocuments,
  ReconciliationSettingsModal,
  BalanceAlignmentModal,
  StatusOverrideModal,
  ReconciliationModal,
  StatusReflectionCard,
  ReconciliationInfoCard,
  createCreditRow,
  getOtherReconciliationMatchedAmount,
  getBillingStatus,
  transformDifferences,
  getBillingImbalanceAmount,
  getMatchedAmount,
  RECONCILIATION_STATUSES,
  RECONCILIATION_STATUS_COLOR,
  RECONCILIATION_TAG_COLOR,
  DOCUMENT_STATUSES,
  DOCUMENT_STATUS_COLOR,
  BILLING_TYPE,
} from '@/modules/reconciliation';
import { useTenancy, useUser } from '@/modules/auth';
import { useSupplier } from '@/modules/suppliers';
import { ContactCard } from '@/modules/contact';
import { useChatModal } from '@/modules/chatModal';
import {
  useBalancePayments,
  PaymentDifferencesMatchingModal,
  formatBilledDate,
  BalancePaymentModal,
  useCreatePayment,
} from '@/modules/payment';
import { DocumentModal } from '@/modules/documentModal';
import { useDocumentsByIds } from '@/modules/document/compositions';
import { CONSOLIDATED_DOCS, INVOICE_DOCS, DOCUMENT_TYPES } from '@/modules/document/types';
import { options } from '@/locale/dateConfig';
import { useCurrency } from '@/modules/core/compositions/money-currency';
import { useUncertainBillings } from '@/modules/billing';
import { usePaymentDelete } from '@/modules/eventMapModal/compositions/payment';
import { ReconciliationBillingRow } from '@/modules/reconciliation';

import { SingularTaskLayout, validateStatusReflection, useHandleTaskUpdate } from './components';
import { useTasks, useBusinessById } from './composition';

const BILLING_PAYMENT_STATE = {
  NO_STATUS: 0,
  NOT_PAYED: 1,
  PARTIALLY_PAYED: 2,
  FULLY_PAYED: 3,
  PAYMENT_IN_PROGRESS: 4,
};

const INNER_TABLE_ACTIONS = {
  OPEN_BILLING_MODAL: 'openBillingModal',
  RESOLVE_MISSING_EVENT: 'resolveMissingEvent',
};

const ACTIONS = {
  OPEN_ISSUES_MODAL: 'issuesModal',
  OPEN_CREDIT_MATCH_MODAL: 'creditMatchModal',
  OPEN_RECONCILIATION_SETTINGS: 'openReconciliationSettings',
  BALANCE_UPDATE: 'balanceUpdate',
  VALIDATE_BALANCE_ALIGNMENT: 'validateBalanceAlignment',
  CLOSE_RECONCILIATION: 'closeReconciliation',
  OPEN_CHAT: 'openChat',
};

const sumAmounts = (amounts) => {
  return amounts.reduce((acc, { amount }) => acc + amount, 0);
};

const timeoutIds = [];
const sleep = async (ms) => new Promise((resolve) => timeoutIds.push(setTimeout(resolve, ms)));

export default {
  components: {
    SingularTaskLayout,
    Button,
    TruncatedText,
    Tag,
    ExchangeIcon,
    ContactCard,
    HourglassIcon,
    ApprovedIcon,
    ChevronIcon,
    QuestionMarkIcon,
    KebabIcon,
    ChatIcon,
    AccountingIcon,
    DocumentMatchingIcon,
    SettingsIcon,
    CheckCircleIcon,
    ChequeredFlagIcon,
    ErrorFullIcon,
    CreditNotes,
    PaymentDifferences,
    ReconciliationDocuments,
    DocumentModal,
    PaymentDifferencesMatchingModal,
    ReconciliationSettingsModal,
    BalanceAlignmentModal,
    BalancePaymentModal,
    StatusOverrideModal,
    ReconciliationModal,
    StatusReflectionCard,
    ReconciliationInfoCard,
    ReconciliationBillingRow,
    EventMapModal: () => import('@/modules/eventMapModal/EventMapModal'),
    CreditMatchModal: () => import('@/modules/activity/components/creditMatchModal/CreditMatchModal'),
    IssuesModal: () => import('@/modules/modals/issuesModal/IssuesModal'),
  },
  props: {
    task: { type: Object, required: true },
    isLoadingTask: { type: Boolean, required: true },
  },
  setup(props) {
    const { $message, $i18n } = getCurrentInstance().proxy;
    const layoutLoading = ref(false);
    const currentTask = ref(props.task);
    const businessId = ref(props.task.businessId);

    watch(
      () => props.task,
      (newTask, oldTask) => {
        if (newTask.id !== oldTask.id) {
          currentTask.value = newTask;
          businessId.value = newTask.businessId;
        }
      }
    );

    const { business, loading: businessLoading } = useBusinessById(businessId);

    const {
      reconciliation,
      loading: reconciliationLoading,
      refetch: reconciliationRefetch,
    } = useReconciliation(computed(() => (props.isLoadingTask ? null : currentTask.value.data.reconciliationId)));

    const loading = computed(
      () => reconciliationLoading.value || props.taskLoading || layoutLoading.value || businessLoading.value
    );

    const { token } = useTenancy();
    const { formatCentsToCurrency } = useCurrency();
    const { isAdmin } = useUser();
    const { supplier } = useSupplier(computed(() => reconciliation.value?.supplierId));

    const dateRange = computed(() => ({
      fromDate: reconciliation.value ? DateTime.fromISO(reconciliation.value?.periodStart).toJSDate() : undefined,
      toDate: reconciliation.value ? DateTime.fromISO(reconciliation.value?.periodEnd).toJSDate() : undefined,
      isDaily: reconciliation.value ? reconciliation.value.periodStart === reconciliation.value.periodEnd : undefined,
    }));

    const { billings: credits } = useBillings(
      computed(() =>
        reject(isNil)({
          businessId: businessId.value,
          supplierId: reconciliation.value?.supplierId,
          from: DateTime.fromJSDate(dateRange.value.fromDate).toISODate(),
        })
      )
    );

    const { balancePayments, refetch: balancePaymentsRefetch } = useBalancePayments(
      computed(() => ({
        businessId: businessId.value,
        supplierId: reconciliation.value?.supplierId,
      })),
      () => ({
        enabled: !!businessId.value && !!reconciliation.value?.supplierId,
      })
    );

    const { eventDifferences, refetch: eventDifferencesRefetch } = useEventDifferences(
      computed(() => ({
        customerId: reconciliation.value && businessId.value,
        fromDate: reconciliation.value?.periodStart,
        toDate: reconciliation.value?.periodEnd,
      }))
    );

    const setDocumentsFromEventReferences = (event) => {
      const doc = event.eventReferences ? event.eventReferences[0].document : event.source?.document;
      if (doc) {
        const document = { id: doc.id, type: doc.type, documentNumber: doc.documentNumber };
        return event.eventReferences
          ? { ...event, eventReferences: [{ ...event.eventReferences[0], document }] }
          : { ...event, source: { ...event.source, document } };
      }
      return event;
    };

    const setDocumentsForPaymentDifference = (paymentDifference) => ({
      ...paymentDifference,
      supplierPayment: {
        ...paymentDifference.supplierPayment,
        eventReferences: paymentDifference.supplierPayment.eventReferences.map((eventReference) => ({
          ...eventReference,
          document: rehandleParentDocuments.value.find((doc) => doc.id === eventReference.documentId),
        })),
      },
    });

    const customerPaymentDifferences = computed(() =>
      eventDifferences.value.paymentDifferences
        .filter(
          ({ customerPayment, supplierPayment }) =>
            !customerPayment && supplierPayment.supplierId === reconciliation.value?.supplierId
        )
        .map(setDocumentsForPaymentDifference)
    );

    const orderDifferences = computed(() =>
      eventDifferences.value.orderDifferences
        .filter(({ supplierId }) => reconciliation.value?.supplierId === supplierId)
        .map((order) => ({
          ...order,
          toValidate: true,
          delivery: eventDifferences.value.deliveryDifferences.find(
            (delivery) => delivery.eventReferences[0].documentId === order.eventReferences[0].documentId
          ),
        }))
        .map(setDocumentsFromEventReferences)
    );

    const unbilledOrdersGroup = computed(() => {
      const { dateRangeOfUnbilled, orderLinks } = (reconciliation.value?.unbilledOrders ?? []).reduce(
        (prev, { date, orderId }) => {
          const millis = DateTime.fromISO(date, { zone: 'UTC' }).toMillis();
          prev.dateRangeOfUnbilled.minDate = Math.min(prev.dateRangeOfUnbilled.minDate, millis);
          prev.dateRangeOfUnbilled.maxDate = Math.max(prev.dateRangeOfUnbilled.maxDate, millis);
          prev.orderLinks.push({ order: { id: orderId } });
          return prev;
        },
        { dateRangeOfUnbilled: { minDate: Number.MAX_VALUE, maxDate: Number.MIN_VALUE }, orderLinks: [] }
      );

      const dateRangeOfCombined = (orderDifferences.value ?? []).reduce((prev, { date }) => {
        const millis = DateTime.fromISO(date, { zone: 'UTC' }).toMillis();
        prev.minDate = Math.min(prev.minDate, millis);
        prev.maxDate = Math.max(prev.maxDate, millis);
        return prev;
      }, { ...dateRangeOfUnbilled } ?? { minDate: Number.MAX_VALUE, maxDate: Number.MIN_VALUE });

      const deliveryRefs = [];
      [...orderLinks].forEach(({ order }) =>
        reconciliation.value?.deliveries.find((delivery) => {
          const foundDelivery = delivery.orderIds.includes(order?.id);
          if (foundDelivery) {
            deliveryRefs.push({ delivery });
          }
        })
      );

      return orderLinks?.length || orderDifferences.value?.length
        ? {
            minDate: dateRangeOfCombined.minDate,
            maxDate: dateRangeOfCombined.maxDate,
            orderLinks: orderLinks ?? [],
            diffs: [],
            imbalances: [],
            deliveryRefs,
            related: [],
            source: {},
            type: BILLING_TYPE.UNBILLED_ORDER,
            orderDifferences: orderDifferences.value,
            supplierId: reconciliation.value?.supplierId,
          }
        : null;
    });

    const {
      deliveryTasks,
      handleReconciliationTasks,
      supplierCreationTasks,
      uncertainBillingTasks,
      balanceAlignmentTasks,
      refetch: tasksRefetch,
    } = useTasks(
      computed(() => ({
        businessId: reconciliation.value && businessId.value,
      }))
    );
    const { uncertainBillings } = useUncertainBillings(computed(() => ({ businessId: businessId.value })));

    const { missingEvents, refetch: refetchMissingEvents } = useMissingEvents(
      () => ({
        businessId: businessId.value,
        supplierId: reconciliation.value?.supplierId,
        estimatedDateFrom: DateTime.fromISO(reconciliation.value?.periodStart).toISODate(),
        estimatedDateTo: DateTime.fromISO(reconciliation.value?.periodEnd).toISODate(),
      }),
      () => ({
        enabled:
          !!businessId.value &&
          !!reconciliation.value?.supplierId &&
          !!reconciliation.value?.periodStart &&
          !!reconciliation.value?.periodEnd,
      })
    );

    const { reconciliationDocuments, loading: reconciliationDocumentsLoading } = useReconciliationDocuments(
      computed(() => ({
        businessId: businessId.value,
        supplierId: reconciliation.value?.supplierId,
        fromDate: DateTime.fromJSDate(dateRange.value.fromDate).minus({ years: 1 }).minus({ days: 1 }).toISODate(),
      }))
    );

    const docIds = computed(() => {
      const missingEventDocs = missingEvents.value
        .filter(({ parentDocumentId }) => parentDocumentId)
        .map(({ parentDocumentId }) => parentDocumentId);

      const paymentDifferencesDocs = flatten(
        eventDifferences.value.paymentDifferences.map(({ supplierPayment }) =>
          supplierPayment?.eventReferences.map(({ documentId }) => documentId)
        )
      );
      return uniq([...missingEventDocs, ...paymentDifferencesDocs]);
    });

    const { documents: rehandleParentDocuments } = useDocumentsByIds(
      computed(() => ({
        businessId: businessId.value,
        ids: reject(isNil)(docIds.value),
      }))
    );

    const missingEventsWithDoc = computed(() =>
      missingEvents.value.map((missingEvent) => ({
        ...missingEvent,
        parentDocument: rehandleParentDocuments.value?.find(
          (document) => document.id === missingEvent.parentDocumentId
        ),
      }))
    );

    const missingBillings = computed(() =>
      missingEventsWithDoc.value
        .filter(({ possibleTypes }) =>
          possibleTypes.some((type) =>
            [...CONSOLIDATED_DOCS, ...INVOICE_DOCS, DOCUMENT_TYPES.INVOICE_RECEIPT].includes(type)
          )
        )
        .map((missingEvent) => ({
          missingEventId: missingEvent.id,
          date: missingEvent.estimatedDate,
          source: {
            document: {
              type: missingEvent.possibleTypes,
              documentNumber: missingEvent.reference,
              parentDocument: missingEvent.parentDocument,
            },
          },
          diffs: [],
          imbalances: [],
          deliveryRefs: [],
          orderLinks: [],
          related: [],
          parentDocument: missingEvent.parentDocument,
          parentDocumentId: missingEvent.parentDocumentId,
        }))
    );

    const { reconciliations } = useReconciliationsSummary(
      computed(() => ({
        businessId: reconciliation.value && businessId.value,
        supplierId: reconciliation.value?.supplierId,
        fromPeriodDate: dateRange.value?.fromDate
          ? DateTime.fromJSDate(dateRange.value?.fromDate).minus({ month: 5 }).toISODate()
          : null,
        toPeriodDate: dateRange.value?.toDate
          ? DateTime.fromJSDate(dateRange.value?.toDate).plus({ month: 5 }).toISODate()
          : null,
      }))
    );

    const orderIds = computed(() => reconciliation.value?.orders.map(({ id }) => id));

    const billedAmounts = computed(() =>
      (reconciliation.value?.billings ?? []).map((billing) => ({
        billingId: billing.id,
        amount: Number((billing.totalAmount / 100).toFixed(2)),
        date: billing.date,
      }))
    );

    const paidAmounts = computed(() => {
      const billingIdToAmount = billedAmounts.value.reduce((acc, { billingId, amount }) => {
        acc[billingId] = amount;
        return acc;
      }, {});

      return (reconciliation.value?.payments ?? []).reduce((acc, { billingLinks, id }) => {
        billingLinks.forEach((billingLink) => {
          if (billingLink.billingId in billingIdToAmount) {
            acc.push({
              paymentId: id,
              billingId: billingLink.billingId,
              amount: billingLink.amount,
            });
          }
        });
        return acc;
      }, []);
    });

    const {
      patchMissingEvent,
      onDone: patchMissingEventOnDone,
      onError: patchMissingEventOnError,
    } = usePatchMissingEvent();

    patchMissingEventOnDone(() => {
      $message.success($i18n.t('billing.billingManagement.messages.missingEventResolvedSuccess'));
      refetchMissingEvents();
      reconciliationRefetch();
    });
    patchMissingEventOnError(() =>
      $message.error($i18n.t('billing.billingManagement.messages.missingEventResolvedError'))
    );

    const {
      reconciliationTemplate,
      loading: reconciliationTemplateLoading,
      refetch: reconciliationTemplateRefetch,
    } = useReconciliationTemplate(
      computed(() => ({
        businessId: businessId.value,
        supplierId: reconciliation.value?.supplierId,
      }))
    );

    const { mutate: createReconciliationTemplate } = useCreateReconciliationTemplate();
    const { mutate: updateReconciliationTemplate } = useUpdateReconciliationTemplate();
    const { mutate: updateReconciliation } = useUpdateReconciliation();
    const { mutate: closeReconciliation } = useCloseReconciliation();

    const { closeChatModal, openChatModal } = useChatModal();
    const reconciliationIds = computed(() => [reconciliation.value?.id]);
    const { channelUnreadMessagesCount } = useChannel(reconciliationIds);

    const refetchData = async () => {
      await sleep(4000);
      await Promise.all([reconciliationRefetch(), balancePaymentsRefetch()]);
    };

    const { onDone: onPaymentDeleteDone } = usePaymentDelete();
    onPaymentDeleteDone(() => {
      refetchData();
    });
    const { onDone: onPaymentCreateDone } = useCreatePayment();
    onPaymentCreateDone(() => {
      refetchData();
    });

    const { handleTaskUpdate } = useHandleTaskUpdate();

    onBeforeUnmount(() => timeoutIds.forEach((timeoutId) => clearTimeout(timeoutId)));

    return {
      currentTask,
      reconciliationTasks: computed(() => [
        ...(handleReconciliationTasks.value ?? []).filter(
          ({ data: { reconciliationId } }) => reconciliationId === reconciliation.value?.id
        ),
        ...(supplierCreationTasks.value ?? []).filter(
          ({ data: { supplierId } }) => supplierId === reconciliation.value?.supplierId
        ),
        ...(uncertainBillingTasks.value ?? []).filter(({ data: { supplierId, uncertainBillingId } }) => {
          const uncertainBilling = (uncertainBillings.value ?? []).find(({ id }) => id === uncertainBillingId);
          const fromDate = reconciliation.value
            ? DateTime.fromISO(reconciliation.value.periodStart).minus({ months: 1 }).startOf('month').toISODate()
            : null;
          const toDate = reconciliation.value
            ? DateTime.fromISO(reconciliation.value.periodEnd).plus({ months: 1 }).endOf('month').toISODate()
            : null;
          return (
            supplierId === reconciliation.value?.supplierId &&
            fromDate <= uncertainBilling?.date &&
            toDate >= uncertainBilling?.date
          );
        }),
        ...(balanceAlignmentTasks.value ?? []).filter(({ data: { reconciliationIds } }) =>
          reconciliationIds.includes(reconciliation.value?.id)
        ),
      ]),
      reconciliation: computed(() => ({
        ...reconciliation.value,
        billings: (() => {
          const allBillings = uniqBy(prop('id'), reconciliation.value?.billings ?? []);
          const charges = allBillings.filter(({ totalAmount }) => totalAmount >= 0);
          const credits = allBillings
            .filter(({ totalAmount }) => totalAmount < 0)
            .map((credit) => {
              const currentReconciliationMatchedAmount = charges
                .filter((billing) => billing.related.some((link) => link.id === credit.id))
                .map((b) =>
                  pluck(
                    'netAmount',
                    b.related.filter((r) => r.id === credit.id)
                  )
                )
                .reduce((total, curr) => total + Math.abs(curr), 0);
              const otherReconciliationMatchedAmount = getOtherReconciliationMatchedAmount(credit, orderIds.value);
              return {
                ...credit,
                currentReconciliationMatchedAmount,
                otherReconciliationMatchedAmount,
                unmatchedAmount:
                  Math.abs(credit.netAmount) - currentReconciliationMatchedAmount - otherReconciliationMatchedAmount,
                imbalances: [],
                deliveryRefs: [],
                related: [],
              };
            });

          const sortDate = (a, b) => new Date(a.date) - new Date(b.date);

          const uncertainBillingsWithType =
            reconciliation.value?.uncertainBillings.map((uncertainBilling) => ({
              ...uncertainBilling,
              type: BILLING_TYPE.UNCERTAIN_BILLING,
            })) ?? [];

          return [
            unbilledOrdersGroup.value,
            ...[...charges, ...missingBillings.value, ...credits, ...uncertainBillingsWithType].sort(sortDate),
          ]
            .filter((b) => b)
            .map((billing) => {
              const imbalanceAmount =
                billing.type !== BILLING_TYPE.UNCERTAIN_BILLING ? getBillingImbalanceAmount(billing.imbalances) : null;
              const matchedAmount = billing.type !== BILLING_TYPE.UNCERTAIN_BILLING ? getMatchedAmount(billing) : null;
              const orderLinks = billing.orderLinks
                ?.map(({ order }) => {
                  const relevantOrder = reconciliation.value?.orders.find(({ id }) => id === order?.id);
                  return {
                    order: relevantOrder ? setDocumentsFromEventReferences(relevantOrder) : null,
                  };
                })
                .filter(({ order }) => order);
              const deliveryRefs = billing.deliveryRefs
                ?.map(({ delivery }) => ({
                  delivery: reconciliation.value?.deliveries.find(({ id }) => id === delivery?.id),
                }))
                .filter(({ delivery }) => delivery);

              const billingPaymentStatus = pipe(
                reject(isNil),
                head
              )(
                billedAmounts.value.map(({ billingId, amount: billedAmount }) => {
                  if (billingId !== billing.id) return;

                  const paymentStatus = paidAmounts.value.reduce(
                    (paidAmounts, { paymentId, billingId: paidBillingId, amount: paidAmount }) => {
                      if (paidBillingId === billingId) {
                        paidAmounts.push({
                          paymentId,
                          amount: paidAmount,
                          completed:
                            reconciliation.value.payments.find(({ id }) => id === paymentId)?.completed ?? false,
                        });
                      }
                      return paidAmounts;
                    },
                    []
                  );

                  const paymentIds = reject(
                    isNil,
                    paymentStatus.map(({ paymentId }) => paymentId)
                  );

                  const paidAmount = sum(paymentStatus.map(({ amount, completed }) => (completed ? amount : 0)));
                  const completed = all(equals(true))(paymentStatus.map(({ completed }) => completed));
                  const leftToPay = billedAmount - paidAmount;

                  const resolveStatus = () => {
                    const baseStatus = {
                      // first payment is picked, since multiple payments are not supported by 'EventMapModal'
                      paymentId: paymentIds?.[0],
                      paidAmount: Number(((paidAmount ?? 0) * 100).toFixed(2)),
                    };
                    if (!paymentIds.length || !billedAmount) {
                      return { ...baseStatus, paymentState: BILLING_PAYMENT_STATE.NOT_PAYED };
                    } else if (leftToPay === 0 && completed) {
                      return { ...baseStatus, paymentState: BILLING_PAYMENT_STATE.FULLY_PAYED };
                    } else if (!completed) {
                      return { ...baseStatus, paymentState: BILLING_PAYMENT_STATE.PAYMENT_IN_PROGRESS };
                    } else {
                      return {
                        ...baseStatus,
                        paymentState: BILLING_PAYMENT_STATE.PARTIALLY_PAYED,
                      };
                    }
                  };
                  return resolveStatus();
                })
              ) || {
                paymentState: BILLING_PAYMENT_STATE.NO_STATUS,
              };

              return {
                ...billing,
                orderLinks,
                deliveryRefs,
                differences:
                  billing.type !== BILLING_TYPE.UNCERTAIN_BILLING ? transformDifferences(orderLinks, billing) : null,
                isMissingDocumentation:
                  billing.type !== BILLING_TYPE.UNCERTAIN_BILLING &&
                  (deliveryTasks.value.some(
                    ({ delivery }) =>
                      delivery.document.id === billing.source?.document?.id ||
                      orderLinks.some(({ order }) => delivery.document.id === order.source?.document?.id)
                  ) ||
                    orderLinks.some(({ order }) => isNil(order.netAmount)) ||
                    (billing.type === BILLING_TYPE.UNBILLED_ORDER && !isEmpty(billing.orderDifferences))),
                missingDeliveryNotes: missingEvents.value.filter(
                  ({ parentDocumentId }) => parentDocumentId === billing.source?.document?.id
                ),
                status: loading.value
                  ? RECONCILIATION_STATUSES.LOADING
                  : getBillingStatus(billing, reconciliation.value?.status),
                imbalanceAmount,
                matchedAmount,
                initialImbalance: imbalanceAmount + matchedAmount,
                paymentStatus: billingPaymentStatus,
              };
            });
        })(),
        billedAmounts: billedAmounts.value,
        paidAmounts: paidAmounts.value,
        totalBilledAmount: billedAmounts.value.length && Number(sumAmounts(billedAmounts.value).toFixed(2)),
        totalPaidAmount: paidAmounts.value.length && Number(sumAmounts(paidAmounts.value).toFixed(2)),
      })),
      reconciliationRefetch,
      layoutLoading,
      dateRange,
      customerPaymentDifferences,
      orderDifferences,
      eventDifferencesRefetch,
      reconciliations,
      reconciliationDocuments,
      reconciliationDocumentsLoading,
      balancePayment: computed(() => balancePayments.value[0]),
      balancePayments,
      balancePaymentsRefetch,
      refetchMissingEvents,
      deliveryTasks,
      credits,
      loading,
      businessId,
      business,
      isAdmin,
      token,
      supplier,
      actionsLoading: computed(() => reconciliationLoading.value || reconciliationTemplateLoading.value),
      reconciliationLoading,
      reconciliationTemplate,
      isSupplierTemplateDefined: computed(() => !!reconciliationTemplate.value?.supplierId),
      reconciliationTemplateRefetch,
      createReconciliationTemplate,
      updateReconciliationTemplate,
      updateReconciliation,
      closeReconciliation,
      contactPopover: ref(false),
      contactUser: ref(null),
      selectedReconciliationId: ref(null),
      displayedDocumentId: ref(null),
      openPaymentDifferencesMatchingModal: ref(false),
      overrideModalShow: ref(false),
      creditMatchModalData: ref(null),
      issueModalData: ref(null),
      reconciliationTemplateParams: ref(null),
      balancePaymentModalSupplier: ref(null),
      activity: ref(null),
      refetchLoading: ref(false),
      patchMissingEvent,
      channelUnreadMessagesCount,
      closeChatModal,
      openChatModal,
      refetchData,
      RECONCILIATION_STATUSES,
      RECONCILIATION_STATUS_COLOR,
      RECONCILIATION_TAG_COLOR,
      DOCUMENT_STATUSES,
      DOCUMENT_STATUS_COLOR,
      BILLING_TYPE,
      BILLING_PAYMENT_STATE,
      INNER_TABLE_ACTIONS,
      ACTIONS,
      statusReflectionForm: reactive({
        update: null,
        reason: reconciliation.value?.statusReflection?.reason ?? '',
      }),
      validationError: ref(false),
      balanceAlignmentReconciliation: ref(null),
      tasksRefetch,
      openSnoozeModal: ref(false),
      handleTaskUpdate,
      formatCentsToCurrency,
    };
  },
  computed: {
    actionsDisabled() {
      return this.statusReflectionForm.update === null;
    },
    taskComment() {
      return this.task.data?.comment ?? '';
    },
    statusOverride() {
      return this.reconciliation.statusOverride;
    },
    mainStatus() {
      return this.reconciliationLoading ? RECONCILIATION_STATUSES.LOADING : this.reconciliation.status?.status;
    },
    periodComplete() {
      return this.reconciliationLoading
        ? RECONCILIATION_STATUSES.LOADING
        : this.reconciliation.status?.details.find(({ key }) => key === 'periodComplete').status;
    },
    snapshotStatus() {
      return this.reconciliationLoading
        ? RECONCILIATION_STATUSES.LOADING
        : this.reconciliation.status?.details.find(({ key }) => key === 'snapshot').status;
    },
    balanceAlignmentStatus() {
      return this.reconciliationLoading
        ? RECONCILIATION_STATUSES.LOADING
        : this.reconciliation.status?.details
            .find(({ key }) => key === 'snapshot')
            .details.find(({ key }) => key === 'balanceAlignment').status;
    },
    paymentDifferenceStatus() {
      return this.reconciliationLoading
        ? RECONCILIATION_STATUSES.LOADING
        : this.reconciliation.status?.details
            .find(({ key }) => key === 'snapshot')
            .details.find(({ key }) => key === 'paymentDifference').status;
    },
    creditNotesStatus() {
      return this.reconciliationLoading
        ? RECONCILIATION_STATUSES.LOADING
        : this.reconciliation.status?.details
            .find(({ key }) => key === 'snapshot')
            .details.find(({ key }) => key === 'creditNotes').status;
    },
    creditNotesToMatch() {
      const creditNotes = this.credits.filter(({ netAmount }) => netAmount < 0).map(createCreditRow);
      return creditNotes
        .filter(({ isFullyMatched }) => !isFullyMatched)
        .sort((a, b) => new Date(a.date) - new Date(b.date));
    },
    billingVerificationStatus() {
      const uncertainBillingDetails = this.reconciliation.status?.details.find(
        ({ key }) => key === 'uncertainBillings'
      );
      const billingDetails = this.reconciliation.status?.details.find(({ key }) => key === 'billings');

      if (this.loading) {
        return RECONCILIATION_STATUSES.LOADING;
      } else if (uncertainBillingDetails) {
        const { status, details } = uncertainBillingDetails;
        if (details.length && status !== RECONCILIATION_STATUSES.APPROVED) {
          return uncertainBillingDetails.status;
        }
      }

      return billingDetails?.status;
    },
    problemCount() {
      let count = 0;
      if (!this.reconciliation.status) return count;
      if (this.balanceAlignmentStatus === this.mainStatus) count++;
      if (this.paymentDifferenceStatus === this.mainStatus) count++;
      if (this.creditNotesStatus === this.mainStatus) count++;

      const billingsStatus = this.reconciliation?.billings.filter(
        ({ status: billingStatus }) => billingStatus === this.mainStatus
      );

      return count + billingsStatus?.length ?? '';
    },
    reconciliationOptions() {
      const beforeCurrent = this.reconciliations
        .filter(({ periodStart }) => this.reconciliation.periodStart > periodStart)
        .slice(0, 5);
      const afterCurrent = this.reconciliations
        .filter(({ periodStart }) => this.reconciliation.periodStart <= periodStart)
        .slice(0, 6);
      const filteredReconciliations = [...beforeCurrent, ...afterCurrent];
      return filteredReconciliations.map(({ id, periodStart, periodEnd }) => ({
        id,
        label:
          periodStart === periodEnd
            ? new Date(periodStart).toLocaleDateString(this.$i18n.locale, options.twoDigits)
            : DateTime.fromISO(periodStart).toLocaleString({ month: 'long', year: 'numeric' }),
        disabled: id === this.reconciliation.id,
      }));
    },
    reconciliationMonth() {
      return DateTime.fromJSDate(this.dateRange.fromDate).toFormat('yyyy-LL');
    },
    supplierPayments() {
      return pluck('supplierPayment', this.customerPaymentDifferences);
    },
    deliveryTasksIds() {
      return this.deliveryTasks.map(({ delivery }) => delivery.document.id);
    },
    taskCloseable() {
      if (!this.reconciliation?.status) return null;
      return (
        this.reconciliation.closed ||
        (this.reconciliation.statusOverride
          ? this.reconciliation.statusOverride.status === 'approved'
          : this.reconciliation.status.status === 'approved')
      );
    },
    balanceAlignmentCardId() {
      return this.getCardId('balanceAlignment');
    },
    paymentDifferencesCardId() {
      return this.getCardId('paymentDifferences');
    },
    creditNotesToMatchCardId() {
      return this.getCardId('creditNotesToMatch');
    },
    statusReflectionDisabled() {
      return !this.statusReflectionForm.update;
    },
    statusReflection() {
      return this.reconciliation?.statusReflection;
    },
  },
  watch: {
    reconciliation(newReconciliation, oldReconciliation) {
      if (newReconciliation.id !== oldReconciliation.id) {
        this.statusReflectionForm.update = null;
        this.statusReflectionForm.reason = newReconciliation.statusReflection?.reason ?? '';
        document.querySelectorAll('.reconciliation-task .collapse.show').forEach((item) => new Collapse(item).hide());
      }
    },
    'statusReflectionForm.update'() {
      this.validationError = false;
    },
  },
  updated() {
    document.querySelectorAll('.reconciliation-task').forEach((item) => {
      item.addEventListener('show.bs.collapse', ({ target: { id } }) => {
        document.getElementById(`card-${id}`)?.classList.add('card-shadow', 'card-expended-border');
        document.querySelector(`#card-${id} .header`)?.classList.add('card-extended-header');
        document.querySelector(`#card-${id} .header button`)?.classList.add('text-typography-primary');
        document.querySelector(`#card-${id} .header .total-amount`)?.classList.add('border-bottom-dashed-primary');
      });
      item.addEventListener('hide.bs.collapse', ({ target: { id } }) => {
        document.getElementById(`card-${id}`)?.classList.remove('card-shadow', 'card-expended-border');
        document.querySelector(`#card-${id} .header`)?.classList.remove('card-extended-header');
        document.querySelector(`#card-${id} .header button`)?.classList.remove('text-typography-primary');
        document.querySelector(`#card-${id} .header .total-amount`)?.classList.remove('border-bottom-dashed-primary');
      });
    });
  },
  methods: {
    completedTask() {
      this.statusReflectionForm.update && this.updateStatusReflection();
      this.$emit('on-task-complete');
    },
    onTaskSkip() {
      this.statusReflectionForm.update && this.updateStatusReflection();
      this.$emit('on-task-skip');
    },
    async validateTaskHook() {
      const warnings = [],
        errors = [];

      if (!this.taskCloseable) {
        errors.push(this.$t('tasks.reconciliationTask.cantClose'));
      }

      return { warnings, errors };
    },
    setLayoutLoading(bool) {
      if (bool) {
        this.closeChatModal();
      }
      this.layoutLoading = bool;
    },
    openContactPopover(user) {
      this.contactPopover = true;
      this.contactUser = user;
    },
    onClickOutside() {
      this.contactPopover = false;
    },
    handleReconciliationSelect(reconciliationId) {
      this.closeChatModal();
      this.selectedReconciliationId = reconciliationId;
    },
    formatDateShort(date, isDaily) {
      if (!date) return '';
      if (isDaily) return new Date(date).toLocaleDateString(this.$i18n.locale, options.twoDigits);
      return new Date(date).toLocaleDateString(this.$i18n.locale, {
        month: 'short',
        year: 'numeric',
      });
    },
    formatMoneyAbs(value) {
      return this.formatCentsToCurrency(Math.abs(Number(value))) ?? '-';
    },
    formatMoney(value) {
      return this.formatCentsToCurrency(value) ?? '-';
    },
    handleOpenDocument(documentId) {
      this.displayedDocumentId = documentId;
    },
    async handlePaymentsMatchSuccess() {
      this.openPaymentDifferencesMatchingModal = false;
      await this.eventDifferencesRefetch();
      if (!this.customerPaymentDifferences.length) {
        document
          .querySelector(`div[data-bs-target='#${this.paymentDifferencesCardId}']`)
          .setAttribute('aria-expanded', 'false');
        document
          .getElementById(`card-${this.paymentDifferencesCardId}`)
          .classList.remove('card-shadow', 'card-expended-border');
        document
          .querySelector(`#card-${this.paymentDifferencesCardId} .header`)
          .classList.remove('card-extended-header');
      }
      await sleep(3000);
      await this.reconciliationRefetch();
    },
    handleOpenMatchCredits(data) {
      this.creditMatchModalData = data;
    },
    handleOpenEventMap(data) {
      this.activity = data;
    },
    handlePaymentLinkClicked(id) {
      this.activity = { type: 'payment', id };
      this.reconciliationRefetch();
    },
    async handleUnbilledOrdersTableRefetch() {
      this.refetchLoading = true;
      try {
        this.eventDifferencesRefetch();
        await sleep(3000);
        await this.reconciliationRefetch();
      } finally {
        this.refetchLoading = false;
      }
    },
    handleBillingTableRefetch() {
      this.reconciliationRefetch();
      this.refetchMissingEvents();
    },
    async handleAction(command) {
      const { id, billedAmounts, unbilledOrders, periodStart, closed, periodEnd } = this.reconciliation;
      switch (command) {
        case ACTIONS.OPEN_ISSUES_MODAL:
          this.issueModalData = {
            supplierId: this.supplier.id,
            month: DateTime.fromJSDate(formatBilledDate([...billedAmounts, ...unbilledOrders], true)).toFormat(
              'yyyy-LL'
            ),
          };
          break;
        case ACTIONS.OPEN_CREDIT_MATCH_MODAL:
          this.creditMatchModalData = {
            supplier: this.supplier,
            month: DateTime.fromJSDate(formatBilledDate([...billedAmounts, ...unbilledOrders], true)).toFormat(
              'yyyy-LL'
            ),
          };
          break;
        case ACTIONS.OPEN_RECONCILIATION_SETTINGS:
          this.reconciliationTemplateParams = {
            supplierId: this.supplier.id,
            template: this.reconciliationTemplate,
          };
          break;
        case ACTIONS.BALANCE_UPDATE:
          this.balancePaymentModalSupplier = this.supplier;
          break;
        case ACTIONS.VALIDATE_BALANCE_ALIGNMENT:
          this.balanceAlignmentReconciliation = this.reconciliation;
          break;
        case ACTIONS.CLOSE_RECONCILIATION:
          this.handleCloseReconciliation(id, periodStart);
          break;
        case ACTIONS.OPEN_CHAT:
          this.handleChatOpen({
            supplier: this.supplier,
            reconciliationId: id,
            reconciliationPeriod: periodStart,
            reconciliationClosed: closed,
            isDaily: periodStart === periodEnd,
          });
          break;
        default:
          break;
      }
    },
    async validateBalanceAlignment({ reconciliationId, amount, note }) {
      try {
        this.balanceAlignmentReconciliation = null;
        await this.updateReconciliation({
          id: reconciliationId,
          patchParams: { balanceAlignment: reject(isNil, { validated: true, amount, note }) },
        });
        this.$message.success(this.$t('commons.messages.action.success'));
        await sleep(4000);
        await this.reconciliationRefetch();
      } catch (error) {
        console.error(error);
        this.$message.error(this.$t('commons.messages.action.error'));
      }
    },
    async handleCloseReconciliation(id, period) {
      this.$confirm(
        this.$t('payment.paymentTable.reconciliationCloseConfirm.text', {
          date: this.formatDateShort(period),
          client: this.supplier.name,
        }),
        this.$t('payment.paymentTable.reconciliationCloseConfirm.title'),
        {
          showClose: false,
          confirmButtonText: this.$t('payment.paymentTable.reconciliationCloseConfirm.confirm'),
          cancelButtonText: this.$t('payment.paymentTable.reconciliationCloseConfirm.cancel'),
          confirmButtonClass: 'el-button--danger',
          cancelButtonClass: 'el-button--secondary',
        }
      )
        .then(async () => {
          try {
            await this.closeReconciliation({ id });
            this.$message.success(this.$t('payment.paymentTable.messages.reconciliationClosedSuccessfully'));
          } catch (error) {
            console.error(error);
            this.$message.error(this.$t('payment.paymentTable.messages.reconciliationClosingFailed'));
          }
        })
        .catch(() => null);
    },
    handleChatOpen({ supplier, reconciliationId, reconciliationPeriod, reconciliationClosed, isDaily }) {
      const formattedPeriod = this.formatDateShort(reconciliationPeriod, isDaily);
      const title = `${this.$t('chat.billingManagement')} - ${formattedPeriod}`;
      this.openChatModal({
        supplier,
        title,
        reconciliationId,
        formattedPeriod,
        isChannelEnabled: !reconciliationClosed,
      });
    },
    async handleReconciliationTemplateSave(params) {
      this.isSupplierTemplateDefined
        ? await this.updateReconciliationTemplate(params)
        : await this.createReconciliationTemplate(params);
      await this.reconciliationTemplateRefetch();
      this.reconciliationTemplateParams = null;
    },
    handleReconciliationModalClose() {
      this.selectedReconciliationId = null;
      this.closeChatModal();
    },
    getCardId(cardName) {
      return `${cardName}-${Math.floor(Math.random() * 1000000)}`;
    },
    updateStatusReflection() {
      this.updateReconciliation({
        id: this.reconciliation.id,
        patchParams: { statusReflection: { reason: this.statusReflectionForm.reason } },
      });
    },
    async taskUpdate(data) {
      this.handleTaskUpdate(data);
      this.tasksRefetch();
    },
    handleValidateStatusReflection() {
      this.validationError = !validateStatusReflection(this.$refs.statusReflection);
    },
    async handleUpdateClick() {
      this.handleValidateStatusReflection();
      if (this.validationError) return;
      this.openSnoozeModal = true;
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';
$gray: #f3f3f4;
$darken-gray: #e3e4e6;

.text-gray {
  color: #46494f;
}

.details-container {
  border: 1px solid #d9dcde;
  border-radius: 4px;
}

.detail {
  color: $typography-secondary;
  width: 160px;
  min-width: 160px;
}

.main-status-circle {
  color: #fff;
  border-radius: 50%;
  padding: 2px;
  width: 24px;
  height: 24px;
  text-align: center;
  font-weight: 500;
  line-height: 1.5;
}

.before-payment-due-circle {
  background-color: #ecf0f3;
  border-radius: 50%;
  padding: 2px;
  width: 24px;
  height: 24px;
  text-align: center;
  font-weight: 500;
}

.el-dropdown-link {
  display: flex;
  align-items: center;

  .reconciliation-title {
    color: $typography-primary;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.reconciliation-select {
  width: 240px;
}

.stepper {
  border-left: 1px solid #e5e8ea;
  margin-top: 4px;
  margin-bottom: 36px;
}

.card .header {
  cursor: pointer;
  height: 52px;
  color: #46494f;
  &:hover:not(svg):not(.credit-circle) {
    background: $gray;
    color: $typography-primary;
    border-radius: 6px;
  }
}

.card .header:hover button {
  color: $typography-primary;
}

div[aria-expanded='true'] {
  border-bottom: 1px solid #e5e8ea;
}

.card-shadow {
  box-shadow: 0 0 4px rgba(19, 26, 36, 0.2);
}

.card-expended-border {
  border: 1px solid #d2d4d7;
}

.card-extended-header {
  background: $gray;
  color: $typography-primary !important;
  border-radius: 6px 6px 0px 0px !important;
  &:hover:not(svg):not(.credit-circle) {
    background: $darken-gray !important;
    border-radius: 6px 6px 0px 0px !important;
  }
}

.border-bottom-dashed-primary {
  border-bottom: 1px dashed $typography-primary;
}

.border-dashed {
  border: 1px dashed $darken-gray;
}

.credit-circle {
  color: #fff;
  border-radius: 50%;
  padding: 2px;
  width: 16px;
  height: 16px;
  text-align: center;
  font-size: 9px;
  background: $primary;
  margin: 0px 8px;
  align-self: center;
}

.disabled {
  color: #94989f !important;
}

.link {
  text-decoration: underline;
  &:hover {
    color: $primary;
  }
}

.hover {
  &:hover {
    color: $primary;
  }
}

.hover-underline {
  &:hover {
    text-decoration: underline;
  }
}

.card .header:hover {
  button {
    color: $typography-primary;
  }
  .total-amount {
    border-bottom: 1px dashed $typography-primary;
  }
}

.total-amount {
  border-bottom: 1px dashed #94989f;
}

.tooltip {
  width: 300px;
}

.approved {
  color: $success;
}

.action-btn {
  height: fit-content;
  &:hover,
  &:active {
    background: $secondary;
  }
}

.p-05 {
  padding: 0.1225rem !important;
}

.on-the-line {
  left: 4px;
}

.status-override:hover {
  text-decoration: none;
}

.status-reflection {
  width: 504px;
}

.red {
  color: #bb0021;
  line-height: 1;
}

.radio-red-border ::v-deep .el-radio__inner {
  border-color: #e52044;
}

::v-deep {
  textarea {
    resize: none;
  }
  .el-radio__label {
    padding-inline-start: 0.5rem !important;
  }
  .el-form-item {
    margin: 0;
  }
  .el-radio__input.is-checked + .el-radio__label {
    color: $typography-primary;
  }
  .el-radio__inner {
    border-width: 2px;
  }
}

.reconciliation-task {
  font-size: $font-size-medium;
}
</style>
